import { getLocation } from "@utils";
import api from ".";
import {
  IResponseAutosuggest,
  IResponseFetchDetail,
  IResponseMountRoute,
} from "@types";
import { IResponseRoute } from "@types";

export const fetchAutosuggest = async (text: string) => {
  const { lat, lon } = await getLocation();

  const response = await api.get<IResponseAutosuggest>(
    "/v2/routes/autosuggest",
    {
      params: {
        lat,
        lon,
        text,
      },
    }
  );
  return response?.data?.items || [];
};

export const mountRoute = async (body: any) => {
  const response = await api.post<IResponseMountRoute>(
    "/v2/routes/mount",
    body
  );
  return response.data;
};

export const createAndSendRoute = async (body: any) => {
  const response = await api.post("v2/routes/rio/send", body);
  return response.data;
};

export const deleteRoute = async (id: any) => {
  console.log(id);
  const response = await api.put("v2/routes/id");
  return response.data;
};

export const getData = async (
  page: any,
  size: any,
  search: any,
  order: any,
  orderBy: String
) => {
  const response = await api.get<IResponseRoute>("/list", {
    params: {
      page,
      size,
      search,
      order,
      orderBy,
    },
  });
  return response?.data || [];
};
export const fetchRoutesList = async (
  page: number,
  size: number,
  search?: string,
  sortB?: string,
  fieldSort?: string
) => {
  const params = new URLSearchParams();
  params.append("size", size.toString());
  params.append("page", page.toString());

  const names = {
    name: "routeName",
    origin: "originName",
    destination: "destinationName",
    stops: "quantityStops",
    distance: "distance",
    duration: "duration",
    register: "routeName",
    vwcoin: "routeName",
    performance: "routeName",
  };

  if (search) {
    params.append("search", search);
  }

  if (sortB) {
    params.append("order", sortB.toUpperCase());
  }

  if (fieldSort && fieldSort in names) {
    params.append("orderBy", names[fieldSort as keyof typeof names]);
  }

  const response = await api.get(`v2/routes/list?${params.toString()}`);
  return response.data;
};

export const fetchDetail = async (id: string) => {
  const response = await api.get<IResponseFetchDetail>(`v2/routes/route/${id}`);
  return response.data;
};

export const updateRoute = async (body: any) => {
  const response = await api.patch(`v2/routes/route/${body.id}`, body);
  return response.data;
};

export const excludeRoute = async (id: string) => {
  const response = await api.put(`v2/routes/${id}`);
  return response.data;
};

export const fetchVehicleList = async (
  page: number,
  size: number,
  search?: string,
  sortB?: string,
) => {
  const params = new URLSearchParams();
  params.append("size", size.toString());
  params.append("page", page.toString());

  if (search) {
    params.append("search", search);
  }

  if (sortB) {
    params.append("order", sortB.toUpperCase());
  }

  const response = await api.get(`v2/routes/vehicles/list?${params.toString()}`);
  return response.data;
};

export const fetchDriverList = async (
  page: number,
  size: number,
  search?: string,
  sortB?: string,
) => {
  const params = new URLSearchParams();
  params.append("size", size.toString());
  params.append("page", page.toString());

  if (search) {
    params.append("search", search);
  }

  if (sortB) {
    params.append("order", sortB.toUpperCase());
  }

  const response = await api.get(`v2/routes/drives/list?${params.toString()}`);
  return response.data;
};