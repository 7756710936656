import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import ApplicationLayout from "@rio-cloud/rio-uikit/ApplicationLayout";
import ApplicationHeader from "@rio-cloud/rio-uikit/ApplicationHeader";
import { useAppSelector } from "@store";

interface Props {
  children?: React.ReactNode;
}

const Page = ({ children }: Props) => {
  const { sidebar } = useAppSelector((state) => state.sidebar);
  return (
    <ApplicationLayout className="bg-lighter">
      <ApplicationLayout.Header>
        <ApplicationHeader
          label="RIO ROUTING"
          navItems={[
            // {
            //   key: "map",
            //   route: <NavLink to="/routing/map/trips">Mapa</NavLink>,
            // },
            {
              key: "trips",
              route: <NavLink to="/routing/trips">Viagens</NavLink>,
            },
            {
              key: "routes",
              route: <NavLink to="/routing/routes">Rotas</NavLink>,
            },
            // {
            //   key: "areas",
            //   route: <NavLink to="/routing/zone">Áreas</NavLink>,
            // },
            // {
            //   key: "perfomance",
            //   route: <NavLink to="/routing/perfomance">Desempenho</NavLink>,
            // },
          ]}
        />
      </ApplicationLayout.Header>
      {sidebar ?? null}
      <ApplicationLayout.Body className="padding-0" innerClassName="padding-0">{children}</ApplicationLayout.Body>
    </ApplicationLayout>
  );
};

export default Page;
