import axios from "axios";

const getTokenFromQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("accessToken");
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${getTokenFromQueryParams()}`,
  },
});

export default api;
