import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISidebarState } from "@types";

const initialState: ISidebarState = {
  sidebar: null,
};

export const SidebarSlice = createSlice({
  name: "Sidebar",
  initialState,
  reducers: {
    setSidebar: (state, action: PayloadAction<JSX.Element | null>) => {
      state.sidebar = action.payload;
    },
  },
});

export const { setSidebar } = SidebarSlice.actions;

export default SidebarSlice.reducer;
