import { RegisterRouter, UpdateRouter } from "@components";
import { ApplicationLayout, Button, Sidebar } from "@rio-cloud/rio-uikit";
import { useAppSelector } from "@store";
import { IRoute, Route } from "@types";
import { IStop } from "../RegisterRoute";

type TProps = {
  sendRoute: (
    origin: IRoute,
    destination: IRoute,
    routes: Route[],
    stops: IStop[],
    value: string,
    accountId: string
  ) => void;
  handleRoutes: (routes: Route[], via: any[]) => void;
};

const UpdateSidebarRoute = ({ sendRoute, handleRoutes }: TProps) => {
  const { origin, destination, routes, stops, routeName, accountId } =
    useAppSelector((state) => state.routes);

  return (
    <ApplicationLayout.Sidebar className="right">
      <Sidebar
        title="Rota"
        closed={false}
        onClose={() => {}}
        position={Sidebar.RIGHT}
        disableClose
        width={500}
      >
        <div className="padding-left-20 padding-top-20 padding-right-20">
          <RegisterRouter
            onChangeRoutes={handleRoutes}
            // dataUpdateRoute={dataUpdateRoute}
            // setDataUpdateRoute={setDataUpdateRoute}
          />
          <>
            <div className="btn-toolbar">
              <Button
                onClick={() =>
                  sendRoute(
                    origin!,
                    destination!,
                    routes,
                    stops,
                    routeName,
                    accountId
                  )
                }
                block
                bsStyle={Button.PRIMARY}
              >
                Editar rota
              </Button>
            </div>
            {/* <div className="btn-toolbar">
              <Button
                onClick={() =>
                  origin && destination
                    ? sendRoute(origin, destination, routes)
                    : null
                }
                block
              >
                Salvar rota e criar viagem
              </Button>
            </div> */}
          </>
        </div>
      </Sidebar>
    </ApplicationLayout.Sidebar>
  );
};

export default UpdateSidebarRoute;
