export const getLocation = (): Promise<{ lat: number; lon: number }> => {
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        () => {
          resolve({
            lat: -23.5475,
            lon: -46.63611,
          });
        }
      );
    }
  });
};
