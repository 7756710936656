interface Props {
    status: string;
  }
  
  const ChipStatus = ({ status }: Props) => {
    const getStatusDetail = (s: string) => {
      let obj = {
        color: "darker text on bg-light",
        text: "DEFAULT",
      };
  
      if (!s) {
        return obj;
      }
  
      switch (s) {
        case "PENDING":
          obj = {
            color: "darker text on bg-lighty",
            text: "Pendente...",
          };
          break;
        case "SCHEDULED":
          obj = {
            color: "darker text on bg-light",
            text: "Agendado",
          };
          break;
        case "ACCEPTED":
          obj = {
            color: "white text on bg-success",
            text: "Aceitou",
          };
          break;
        case "REFUSED":
          obj = {
            color: "white text on bg-danger",
            text: "Recusou",
          };
          break;
        default:
          obj = {
            color: "darker text on bg-light",
            text: "DEFAULT",
          };
          break;
      }
  
      return obj;
    };
  
    const statusRc = getStatusDetail(status);
  
    return (
      <div
        className={`width-120 ${statusRc.color} rounded-extra-large padding-4 display-flex flex-direction-row align-items-center justify-content-center`}
      >
        {statusRc.text}
      </div>
    );
  };
  
  export default ChipStatus;