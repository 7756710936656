import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoute, IRoutesState, Route } from "@types";
import { IStop } from "../../components/RegisterRoute";

const initialState: IRoutesState = {
  origin: null,
  destination: null,
  routes: [],
  stops: [],
  routeName: "",
  accountId: "",
};

export const RoutesSlice = createSlice({
  name: "Routes",
  initialState,
  reducers: {
    defineOrigin: (state, action: PayloadAction<IRoute | null>) => {
      state.origin = action.payload;
    },
    defineDestination: (state, action: PayloadAction<IRoute | null>) => {
      state.destination = action.payload;
    },
    defineRoutes: (state, action: PayloadAction<Route[]>) => {
      state.routes = action.payload;
    },
    defineStops: (state, action: PayloadAction<IStop[]>) => {
      state.stops = action.payload;
    },
    defineRouteName: (state, action: PayloadAction<string>) => {
      state.routeName = action.payload;
    },
    defineAccountId: (state, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },
  },
});

export const {
  defineOrigin,
  defineDestination,
  defineRoutes,
  defineStops,
  defineRouteName,
  defineAccountId,
} = RoutesSlice.actions;

export default RoutesSlice.reducer;
