import { useEffect, useState } from "react";

import ExpanderPanel from "@rio-cloud/rio-uikit/ExpanderPanel";
import Button from "@rio-cloud/rio-uikit/Button";
import "./routes.css";
import Checkbox from "@rio-cloud/rio-uikit/Checkbox";
import ClearableInput from "@rio-cloud/rio-uikit/ClearableInput";
import Notification from "@rio-cloud/rio-uikit/Notification";
import Slider from "@rio-cloud/rio-uikit/Slider";
import NumberInput from "@rio-cloud/rio-uikit/NumberInput";
import DataTab from "@rio-cloud/rio-uikit/DataTab";
import DataTabs from "@rio-cloud/rio-uikit/DataTabs";
import Autosuggest from "../Autosuggest";
import { fetchAutosuggest, mountRoute } from "src/services/routes";
import { AutoSuggestSuggestion } from "@rio-cloud/rio-uikit/components/autosuggest/AutoSuggest";
import moment from "moment";
import { Route } from "@types";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import { NavLink } from "react-router-dom";
import RadioButton from "@rio-cloud/rio-uikit/RadioButton";

import SubNavigation from "@rio-cloud/rio-uikit/SubNavigation";
import { useDispatch } from "react-redux";
import {
  defineDestination,
  defineOrigin,
  defineRoutes,
  defineStops,
} from "src/store/routes/RoutesSlice";
import { useAppSelector } from "@store";

export const Title = ({
  text,
  value,
}: {
  text: string;
  value: React.ReactNode;
}) => (
  <div>
    <div>{text}</div>
    <div className="text-bold text-size-18">{value}</div>
  </div>
);

type TProps = {
  onChangeRoutes: (routes: Route[], via: any[]) => void;
};

export interface IStop {
  id?: string;
  position: any;
  stayTime: string;
  category: string;
  order: number;
}

const INITIAL_STOP = {
  position: null,
  stayTime: "00:00",
  category: "LUNCH_REST",
  order: 1,
};

const RegisterRoute = ({ onChangeRoutes }: TProps) => {
  const dispatch = useDispatch();

  const [origin, setOrigin] = useState<any>(null);
  const [destination, setDestination] = useState<any>(null);
  const [routes, setRoutes] = useState<Route[]>([]);
  const [via, setVia] = useState<IStop[]>([]);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [stop, setStop] = useState({ ...INITIAL_STOP });
  const [changed, setChanged] = useState(false);

  const {
    origin: originStore,
    destination: destinationStore,
    stops,
    routes: routesStore,
  } = useAppSelector((state) => state.routes);

  useEffect(() => {
    if (!origin && originStore) {
      setOrigin(originStore);
    }
    if (!destination && destinationStore) {
      setDestination(destinationStore);
    }
    if (!via.length && stops.length) {
      setVia(stops);
    }
  }, [originStore, destinationStore, stops]);

  useEffect(() => {
    if (!routes.length && routesStore.length) {
      const { distance, duration } = (
        routesStore[0]?.sections || []
      ).reduce(
        (acum, curr) => {
          acum.distance += curr.summary.length;
          acum.duration += curr.summary.duration;
          return acum;
        },
        { distance: 0, duration: 0 }
      );
      setDistance(convertMetersToKm(distance));
      setDuration(convertSecondsToHours(duration));
    }
  }, [routesStore]);

  useEffect(() => {
    if (origin) {
      dispatch(
        defineOrigin({ lat: origin.lat, lng: origin.lng, label: origin.label })
      );
    } else {
      dispatch(defineOrigin(null));
    }
    if (destination) {
      dispatch(
        defineDestination({
          lat: destination.lat,
          lng: destination.lng,
          label: destination.label,
        })
      );
    } else {
      dispatch(defineDestination(null));
    }
    dispatch(defineStops(via));
    if (changed) {
      if (origin && destination) {
        handleRoute();
      } else {
        setRoutes([]);
      }
    }
  }, [origin, destination, via]);

  useEffect(() => {
    onChangeRoutes(routes, via);
    dispatch(defineRoutes(routes));
  }, [routes]);

  const handleRoute = async () => {
    if (origin && destination) {
      const body = {
        origin: { lat: origin.lat, lng: origin.lng },
        destination: { lat: destination.lat, lng: destination.lng },
        via: via.map((t) => ({ lat: t.position.lat, lng: t.position.lng })),
        vehicle: {},
        departureTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
      };
      const response = await mountRoute(body);
      setRoutes(response.routes);
      const { distance, duration } = (
        response.routes[0]?.sections || []
      ).reduce(
        (acum, curr) => {
          acum.distance += curr.summary.length;
          acum.duration += curr.summary.duration;
          return acum;
        },
        { distance: 0, duration: 0 }
      );
      setDistance(convertMetersToKm(distance));
      setDuration(convertSecondsToHours(duration));
    }
  };

  const convertSecondsToHours = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${hours}h${minutes}min`;
  };

  const convertMetersToKm = (valueInMeters: number) => {
    if (valueInMeters < 1000) {
      return `${valueInMeters} M`;
    } else {
      const valueInKm = (valueInMeters / 1000).toFixed(2);
      return `${valueInKm} Km`;
    }
  };

  const [openModal, setOpenModal] = useState(false);

  const DynamicContent = () => {
    return (
      <fieldset className="col-sm-4">
        <p>
          <Checkbox label="Rota com menor consumo energético" />
        </p>
        <p>
          <Checkbox label="Rota mais rápida" />
        </p>
        <p>
          <Checkbox label="Rota mais econômica" />
        </p>
      </fieldset>
    );
  };

  const DynamicContentSimuilador = () => {
    const [selectedOption, setSelectedOption] = useState(1);
    return (
      <div className="col-12">
        <div className="veiculo">
          Veículo
          <div>
            <p>
              <RadioButton
                name="radioGroup"
                onChange={() => setSelectedOption(1)}
                checked={selectedOption === 1}
                inline
              >
                Combustível
              </RadioButton>
              <RadioButton
                name="radioGroup"
                onChange={() => setSelectedOption(2)}
                checked={selectedOption === 2}
                inline
              >
                Elétrico
              </RadioButton>
            </p>
          </div>
        </div>
        <div className="display-flex flex-wrap gap-15">
          <div className="form-group">
            <label htmlFor="inputWithMask1">Consumo veículo (km/litro)</label>
            <ClearableInput
              id="inputWithMask1"
              mask=""
              alwaysShowMask
              className="min-width-80"
            />
          </div>
          <div className="form-group-2">
            <label htmlFor="inputWithMask1">Preço combustível (R$/litro)</label>
            <ClearableInput
              id="inputWithMask1"
              mask=""
              alwaysShowMask
              className="min-width-90"
            />
          </div>
          <div className="btn-toolbar">
            <Button className="botao-com-calcular">Calcular</Button>
          </div>
        </div>
      </div>
    );
  };

  const DynamicContentPreferencias = () => {
    return (
      <fieldset className="col-sm-4">
        <p>
          <Checkbox label="Evitar pedágio" />
        </p>
      </fieldset>
    );
  };

  const fetchDataAutosuggestAddress = async (text: string) => {
    const response = await fetchAutosuggest(text);
    const data: AutoSuggestSuggestion[] = [];
    response.forEach((i) => data.push({ label: i.title, ...i } as any));
    return data;
  };

  const DynamicContentDesvio = () => {
    const [value1, setValue1] = useState(4);
    return (
      <div className="display-flex flex-column gap-20">
        <div>
          <div className="display-flex flex-wrap gap-15">
            <div className="width-220">
              <Checkbox>Definir tolerância de desvio da rota</Checkbox>
              <Slider
                value={value1}
                minValue={0}
                maxValue={20}
                step={1}
                onDragEnd={(value) => {
                  Notification.info(`New value: ${value}`);
                  setValue1(value);
                }}
              />
            </div>
            <div className="width-150">
              <NumberInput placeholder="15 Km" bsSize="sm" className="Test" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onClear = (index: number) => {
    setChanged(true);
    setVia((previous) => {
      const via = previous.filter((_, i) => index !== i);
      return via.map((item, i) => ({ ...item, order: i + 1 }));
    });
  };

  const DynamicContentAreas = () => {
    const [tabKey, setTabKey] = useState<string>();

    return (
      <div className="bg-blank border">
        <DataTabs
          activeKey={tabKey}
          onSelectTab={(newTabKey) => setTabKey(newTabKey)}
        >
          <DataTab tabKey="tab1" title={<Title text="" value="Opcionais" />}>
            <div className="contentArea">
              {"Nenhuma área opcional adicionada"}
            </div>
          </DataTab>
          <DataTab
            tabKey="tab2"
            title={<Title text="" value="Globais" />}
          ></DataTab>
        </DataTabs>
        <br />
        <div className="btn-toolbar max-width-500">
          <Button block>Adicionar área</Button>
        </div>
      </div>
    );
  };

  const formatTimeToText = (time: string): string => {
    const [hours, minutes] = time.split(":").map(Number);
    const hourText = hours > 0 ? `${hours} hora${hours > 1 ? "s" : ""}` : "";
    const minuteText =
      minutes > 0 ? `${minutes} minuto${minutes > 1 ? "s" : ""}` : "";

    return [hourText, minuteText].filter(Boolean).join(" e ");
  };

  const formatCategory = (text: string) => {
    const DICT = {
      LUNCH_REST: "Refeição",
      LOAD_UNLOAD: "Coleta de carga",
      END_OF_DAY: "Fim de jornada de trabalho",
      DEALER: "Concessionária",
    };

    return DICT[text as "LUNCH_REST" | "LOAD_UNLOAD" | "END_OF_DAY" | "DEALER"];
  };

  const reorderArray = (index: number, direction: 1 | -1) => {
    if (index < 0 || index >= via.length) return;

    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= via.length) return;

    const newArray = [...via];
    [newArray[index], newArray[newIndex]] = [
      newArray[newIndex],
      newArray[index],
    ];

    setVia(newArray);
  };

  return (
    <>
      <div className="max-width-500  display-grid gap-10">
        <div className="callout callout-primary border-none display-flex align-items-center shadow-smooth">
          <div className="flex-1">
            <div className="text-medium text-size-20 line-height-125rel display-flex align-items-center">
              <span className="rioglyph rioglyph-road text-size-16 margin-right-10"></span>
              <span className="text-size-16 text-medium line-height-15">
                Distância: {distance || "0KM"}
              </span>
            </div>

            <div>
              {/* <span className="rioglyph text-color-gray rioglyph-filling-e-station text-size-16 margin-right-10"></span>
              <span className="text-color-gray text-size-12 line-height-15">
                Estimativa média de consumo de combustível: 0l
              </span> */}
            </div>

            <div>
              {/* <span className="rioglyph text-color-gray rioglyph-globe text-size-16 margin-right-10"></span>
              <span className="text-color-gray text-size-12 line-height-15">
                Estimativa média de emissão de carbono: 0kg CO2
              </span> */}
            </div>
          </div>
          <div
            className="display-flex justify-content-center"
            style={{ flex: 1 }}
          >
            <h1 className="text-color-primary ">{duration || "0h00m"}</h1>
          </div>
        </div>

        <div className="display-grid gap-10">
          <div>
            <div className="grid-colspan-1 grid-colspan-2-lg">
              <label className="text-color-dark">Origem</label>
            </div>
            <div>
              <div className="display-flex align-items-center">
                <Autosuggest
                  fetchData={fetchDataAutosuggestAddress}
                  placeholder="Informe a origem"
                  icon="rioglyph-start"
                  defaultValue={origin?.label || ""}
                  onClear={() => setOrigin(null)}
                  onSuggestionSelected={(e, i) => {
                    setChanged(true);
                    setOrigin({
                      ...(i.suggestion as any).position,
                      ...i.suggestion,
                    });
                  }}
                />
                <div className="btn-toolbar">
                  <Button
                    bsStyle={Button.PRIMARY}
                    iconOnly
                    iconName="rioglyph-plus-light"
                    onClick={() => setOpenModal(true)}
                    className="margin-left-10"
                  />
                </div>
              </div>
            </div>
          </div>
          {via.map((item, index) => (
            <div>
              <div className="display-flex align-items-center">
                <div className="width-40"></div>
                <label className="text-color-dark">
                  {formatCategory(item.category)} -{" "}
                  {formatTimeToText(item.stayTime)}
                </label>
              </div>
              <div className="display-flex align-items-center">
                <div className="width-40 padding-left-10">
                  {via.length > 1 ? (
                    <>
                      <div
                        style={{ color: "#30B4C0", cursor: "pointer" }}
                        className="rioglyph rioglyph-chevron-up"
                        onClick={() => reorderArray(index, -1)}
                      ></div>
                      <div
                        style={{ color: "#30B4C0", cursor: "pointer" }}
                        className="rioglyph rioglyph-chevron-down"
                        onClick={() => reorderArray(index, 1)}
                      ></div>
                    </>
                  ) : null}
                </div>
                <Autosuggest
                  fetchData={fetchDataAutosuggestAddress}
                  placeholder="Informe a parada"
                  icon="rioglyph-pushpin"
                  defaultValue={item?.position?.label || ""}
                  onClear={() => {
                    onClear(index);
                  }}
                  onSuggestionSelected={(e, i) => {
                    setChanged(true);
                    // setOrigin({
                    //   ...(i.suggestion as any).position,
                    //   ...i.suggestion,
                    // });
                  }}
                />
                <div className="btn-toolbar">
                  <Button
                    bsStyle={Button.PRIMARY}
                    iconOnly
                    iconName="rioglyph-plus-light"
                    onClick={() => setOpenModal(true)}
                    className="margin-left-10"
                  />
                </div>
              </div>
            </div>
          ))}
          <div>
            <div className="grid-colspan-1 grid-colspan-2-lg">
              <label className="text-color-dark">Destino</label>
            </div>
            <Autosuggest
              fetchData={fetchDataAutosuggestAddress}
              placeholder="Informe o destino"
              icon="rioglyph-finish"
              defaultValue={destination?.label || ""}
              onClear={() => setDestination(null)}
              onSuggestionSelected={(e, i) => {
                setChanged(true);
                setDestination({
                  ...(i.suggestion as any).position,
                  ...i.suggestion,
                });
              }}
            />
          </div>
          <div className="separator ">
            {/* <ExpanderPanel
              title="Otimizar"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContent />
            </ExpanderPanel>

            <ExpanderPanel
              title="Preferências"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContentPreferencias />
            </ExpanderPanel>

            <ExpanderPanel
              title="Desvio da rota"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContentDesvio />
            </ExpanderPanel>

            <ExpanderPanel
              title="Áreas"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContentAreas />
            </ExpanderPanel>

            <ExpanderPanel
              title="Simulador de custos"
              bsStyle="blank"
              titleClassName="text-bold margin-y-10"
              bodyClassName="padding-0"
            >
              <DynamicContentSimuilador />
            </ExpanderPanel> */}
          </div>
        </div>
      </div>

      <Dialog
        show={openModal}
        title={"Adicionar parada"}
        onClose={() => setOpenModal(false)}
        showCloseButton
        bsSize="md"
        body={
          <div className="display-flex">
            <div
              style={{
                width: 56,
                height: 56,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 24,
                marginLeft: 24,
              }}
              className="border border-color-primary border-width-3
                       text-center"
            >
              <span
                className="rioglyph rioglyph-pushpin text-color-primary"
                style={{ fontSize: 32 }}
              />
            </div>
            <div className="display-grid gap-10" style={{ flex: 1 }}>
              <div>
                <label className="text-color-dark">Local da parada</label>
                <Autosuggest
                  fetchData={fetchDataAutosuggestAddress}
                  placeholder="Informe a parada"
                  onSuggestionSelected={(e, i) => {
                    setStop((previous) => ({
                      ...previous,
                      position: {
                        ...(i.suggestion as any).position,
                        ...i.suggestion,
                      },
                    }));
                  }}
                />
              </div>
              <div className="form-group padding-left-0">
                <label htmlFor="select">Tipo de parada</label>
                <select
                  className="form-control input-lg"
                  id="select"
                  onChange={(event) => {
                    setStop((previous) => ({
                      ...previous,
                      category: event.target.value,
                    }));
                  }}
                >
                  <option value="LUNCH_REST">Refeição</option>
                  <option value="LOAD_UNLOAD">Coleta de carga</option>
                  <option value="END_OF_DAY">Fim de jornada de trabalho</option>
                  <option value="DEALER">Concessionária</option>
                </select>
              </div>
              <div>
                <label htmlFor="time">Tempo de permanência</label>
                <input
                  id="time"
                  type="time"
                  className="form-control input-lg"
                  value={stop.stayTime}
                  onChange={(event) => {
                    setStop((previous) => ({
                      ...previous,
                      stayTime: event.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        }
        footer={
          <div className="display-flex gap-10 justify-content-end">
            <Button
              bsStyle="primary"
              className="btn btn-primary btn-outline btn-lg width-100"
              onClick={() => {
                setStop({ ...INITIAL_STOP });
                setOpenModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={stop.stayTime === "00:00" || !stop.position}
              bsStyle="primary"
              className="btn-lg width-100"
              onClick={() => {
                setStop({ ...INITIAL_STOP });
                setChanged(true);
                setVia((previous) => [
                  ...previous,
                  { ...stop, order: previous.length + 1 },
                ]);
                setOpenModal(false);
              }}
            >
              Salvar
            </Button>
          </div>
        }
      />
    </>
  );
};
export default RegisterRoute;
