interface Props {
    text: string;
    icon?: string;
    background: string;
  }
  
  const Chip = ({ text, icon, background }: Props) => {
    return (
      <div className={`width-90 ${background} rounded-small padding-4 display-flex flex-direction-row align-items-center justify-content-center`}>
        {icon ? (
          <span className={`${icon} text-size-10`} />
        ) : null}
        <span className="padding-x-4 font-weight-400 text-size-10 text-normal">
          {text}
        </span>
      </div>
    );
  };
  
  export default Chip;
  