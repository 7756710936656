interface Props {
  pages: number;
  page: number;
  handlePage: (id: number) => void;
}

const Paginator = ({ pages, page, handlePage }: Props) => {
  return (
    <div
      style={{
        marginTop: 40,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <ul
        className="pagination"
        style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
      >
        <li
          className={page === 1 ? "disabled" : ""}
          onClick={() => (page - 1 === 1 ? null : handlePage(page - 1))}
        >
          <span className="rioglyph rioglyph-chevron-left"></span>
        </li>

        {Array.from({ length: pages }, (_, i) => (
          <li
            key={i}
            onClick={() => handlePage(i + 1)}
            style={{ background: i + 1 === page ? "#A7AFBB" : "" }}
          >
            <span style={{ color: i + 1 === page ? "white" : "" }}>
              {i + 1}
            </span>
          </li>
        ))}

        <li className={page + 1 > page ? "disabled" : ""}>
          <span
            className="rioglyph rioglyph-chevron-right"
            onClick={() => (page + 1 > page ? null : handlePage(page + 1))}
          ></span>
        </li>
      </ul>
    </div>
  );
};

export default Paginator;
