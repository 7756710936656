/* eslint-disable react-hooks/exhaustive-deps */
import PageContainer from "../../components/routes/PageContainer";
import { useEffect, useState } from "react";
import {
  excludeRoute,
  fetchRoutesList,
  fetchVehicleList,
} from "src/services/routes";
import { Notification, TableSearch } from "@rio-cloud/rio-uikit";
import { Dialog, RoutesTableTrip } from "@components";
import { useDispatch } from "react-redux";
import { setIdRouteSelected, setIdVehicleSelected, setNameVehicleSelected } from "@store";
import { navigateTo } from "@utils";

const TripsListRouteScreen = () => {
  const [routes, setRoutes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("desc");
  const [fieldSort, setFieldSort] = useState("name");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [openModalVehicle, setOpenModalVehicle] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [searchVehicle, setSearchVehice] = useState("");
  const [pageVehicle, setPageVehicle] = useState(1);
  const [pagesVehicle, setPagesVehicle] = useState(1);

  const dispatch = useDispatch();

  const handleUpdateRoute = (id: string) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set("id", id);
    const url = "/routing/routes/update?" + currentParams.toString();
    window.location.href = url;
  };

  const initialData = async (pageToSearch?: number) => {
    try {
      const res = await fetchRoutesList(
        pageToSearch || page,
        10,
        searchValue,
        sort,
        fieldSort
      );

      if (res.data.result.routes) {
        setRoutes(res.data.result.routes);
        setPages(res.data.result.pages);
      }
    } catch (error) {
      Notification.error("Não foi possível buscar as rotas.");
    } finally {
      if (pageToSearch) {
        setPage(pageToSearch);
      }
    }
  };

  const initialVehicleData = async () => {
    try {
      const res = await fetchVehicleList(pageVehicle, 10, searchVehicle, "ASC");
      if (res.data.result) {
        
        setVehicles(res.data.result.items);
        setPagesVehicle(res.data.result.pages)
      }
    } catch (error) {
     
    }
  };

  useEffect(() => {
    initialVehicleData();
  }, [pageVehicle, searchVehicle]);

  useEffect(() => {
    initialData();
  }, [page, searchValue, sort]);

  const handleDeleteRoute = async (id: string) => {
    try {
      await excludeRoute(id);
      Notification.info("Rota excluída com sucesso!");
      initialData(page > 1 && routes.length === 1 ? page - 1 : page);
    } catch (error) {
      Notification.error("Erro ao excluir rota!");
    }
  };

  const handleSelectedRoute = (id: string) => {
    dispatch(setIdRouteSelected(id));
    setOpenModalVehicle(true);
  };

  const handleSaveVehicle = (id: string, name: string) => {
    setOpenModalVehicle(false);
    dispatch(setIdVehicleSelected(id));
    dispatch(setNameVehicleSelected(name))
    navigateTo("/routing/trips/driver");
  };

  return (
    <PageContainer>
      <div>
        <div className="display-flex align-items-center gap-20">
          <div className="border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
            <span
              className={`rioglyph rioglyph-route-option text-size-h1 text-color-primary`}
            />
          </div>
          <p className="text-size-h2 text-bold">
            Selecionar rota para a viagem
          </p>
        </div>
      </div>

      <div>
        <TableSearch
          value={searchValue}
          onChange={(text) => setSearchValue(text)}
          placeholder="Buscar em rotas"
        />
      </div>

      <RoutesTableTrip
        handleSelect={handleSelectedRoute}
        pages={pages}
        handleSort={(dir, field) => {
          setSort(dir);
          setFieldSort(field);
        }}
        routes={routes}
        viewType="TABLE"
        page={page}
        handleUpdateRoute={handleUpdateRoute}
        handleSearchValueChange={(text) => setSearchValue(text)}
        handleDeleteRoute={handleDeleteRoute}
        searchValue={searchValue}
        handlePage={(page) => {
          setPage(page);
        }}
      />

      <Dialog.AddVehicle
        inputValue={searchVehicle}
        onChangeInput={(text) => setSearchVehice(text)}
        vehicles={vehicles}
        onClose={() => setOpenModalVehicle(false)}
        handleCancel={() => {
          setOpenModalVehicle(false);
        }}
        open={openModalVehicle}
        handleSaveVehicle={handleSaveVehicle}
        page={pageVehicle}
        pages={pagesVehicle}
        handlePage={(pageN) => {
          setPageVehicle(pageN);
        }}
      />
    </PageContainer>
  );
};

export default TripsListRouteScreen;
