import CardCount from "src/components/CardCount";
import PageContainer from "../../components/routes/PageContainer";
import { useEffect, useState } from "react";
import { Notification } from "@rio-cloud/rio-uikit";
import { Dialog, TableTrips } from "@components";
import { fetchTripsList } from "src/services/trips";
import { navigateTo } from "@utils";

const TripsScreen = () => {
  const [routes, setRoutes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("desc");
  const [fieldSort, setFieldSort] = useState("name");
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [pages, setPages] = useState(1);
  const [dashboard, setDashboard] = useState({
    scheduled: 0,
    new: 0,
    pending: 0,
    refused: 0,
  });

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleUpdateRoute = (id: string) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set("id", id);
    const url = "/routing/routes/update?" + currentParams.toString();
    window.location.href = url;
  };

  const handleSelectTrip = () => {
    navigateTo("/routing/trips/routes");
  };

  const initialData = async () => {
    try {
      const res = await fetchTripsList(page, 10, searchValue, sort, fieldSort);
      if (res.data.result) {
        setRoutes(res.data?.result?.trips);
        setDashboard({
          pending: res.data.result.pending,
          scheduled: res.data.result.schedules,
          new: res.data.result.suggestion,
          refused: res.data.result.refused,
        });

        setPages(res.data.result?.pages > 0 ? res.data.result?.pages : 1);
      }
    } catch (error) {
      Notification.error("Não foi possível buscar as rotas.");
    }
  };

  useEffect(() => {
    initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchValue, sort]);

  const handleDownload = () => {
    console.log("donwload");
  };

  return (
    <PageContainer>
      <div style={{ display: "flex", gap: 24 }}>
        <div style={{width: 400}}>
          <CardCount
            borderColor="#5CB85C"
            label="VIAGENS AGENDADAS"
            value={dashboard.new}
          />
        </div>
        {/* <CardCount
          borderColor="#FF8E3C"
          label="NOVA DIA E HORA SUGERIDOS"
          value={dashboard.new}
        />
        <CardCount
          borderColor="#D0D8DE"
          label="ACEITE PENDENTE"
          value={dashboard.pending}
        />
        <CardCount
          borderColor="#D90000"
          label="VIAGENS RECUSADAS"
          value={dashboard.refused}
        /> */}
      </div>
      <TableTrips
        pages={pages}
        handleDownload={handleDownload}
        handleSort={(dir, field) => {
          setSort(dir);
          setFieldSort(field);
        }}
        routes={routes}
        viewType="TABLE"
        page={page}
        handleCreateRoute={handleOpenModal}
        handleUpdateRoute={handleUpdateRoute}
        handleSearchValueChange={(text) => setSearchValue(text)}
        searchValue={searchValue}
        handlePage={(page) => {
          setPage(page);
        }}
      />
      <Dialog.AddTrip
        open={openModal}
        onClose={handleCloseModal}
        onClickSelectRoute={handleSelectTrip}
      />
    </PageContainer>
  );
};

export default TripsScreen;
