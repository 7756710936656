import { combineReducers } from "@reduxjs/toolkit";
import { ExampleSlice } from "./example/ExampleSlice";
import { SidebarSlice } from "./sidebar/SidebarSlice";
import { RoutesSlice } from "./routes/RoutesSlice";
import { TripsSlice } from "./trips/TripsSlice";

export const reducers = combineReducers({
  example: ExampleSlice.reducer,
  sidebar: SidebarSlice.reducer,
  routes: RoutesSlice.reducer,
  trips: TripsSlice.reducer,
});
