import React, { useEffect, useRef, useState } from "react";
import { AutoSuggest } from "@rio-cloud/rio-uikit";
import {
  AutoSuggestSuggestion,
  SelectedSuggestion,
} from "@rio-cloud/rio-uikit/components/autosuggest/AutoSuggest";

type TProps = {
  onSuggestionSelected?: (
    event:
      | React.MouseEvent<HTMLLIElement>
      | React.KeyboardEvent<HTMLDivElement>,
    selectedSuggestion: SelectedSuggestion
  ) => void;
  placeholder: string;
  icon?: string;
  valueProp?: string;
  fetchData: (text: string) => Promise<any>;
  defaultValue?: string;
  onClear?: () => void;
};

const Autosuggest = ({
  onSuggestionSelected,
  placeholder,
  fetchData,
  icon,
  defaultValue,
  onClear,
}: TProps) => {
  const [value, setValue] = useState(defaultValue || "");
  const [key] = useState(Math.random());
  const [suggestions, setSuggestions] = useState<AutoSuggestSuggestion[]>([]);

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  const handleSuggestionsFetchRequested = async (result: { value: string }) => {
    try {
      const newValue = result.value;
      setValue(newValue);
      if (newValue) {
        const response = await fetchData(newValue);
        setSuggestions(response);
      }
    } catch (error) {
      setSuggestions([]);
    }
  };

  const renderSuggestion = (suggestion: AutoSuggestSuggestion) => (
    <span>{suggestion.label}</span>
  );

  return (
    <AutoSuggest
      key={key}
      className="width-100pct"
      inputProps={{
        placeholder,
        value,
        icon,
        className: "width-100pct",
        onClear,
      }}
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      renderSuggestion={renderSuggestion}
      closeOnSelect={true}
      onSuggestionSelected={onSuggestionSelected}
    />
  );
};

export default Autosuggest;
