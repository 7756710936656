import { configureStorage } from './storage';

export const extractAccessTokenFromWindowLocation = (window: Window): string | undefined => {
  if (!window || !window.location || !window.location.href) {
    return undefined;
  }

  if (typeof window.location.href !== 'string') {
    return undefined;
  }

  let token: string | undefined;
  window.location.href.replace(/access_token=([^&]+)/u, (_, it: string) => {
    token = it;
    return '';
  });

  return token;
};


export const configureAccessToken = (window: Window | null, storage: { discardAccessToken?: () => void; getAccessToken?: () => any; hasAccessToken?: () => boolean; saveAccessToken: any; discardIdToken?: () => void; getIdToken?: () => any; hasIdToken?: () => boolean; saveIdToken?: (token: any) => void; }) => {
  const urlToken = extractAccessTokenFromWindowLocation(window as Window);

  if (urlToken) {
    storage.saveAccessToken(urlToken);
  }

  return storage;
};

export const accessToken = configureAccessToken(
  typeof window === 'undefined' ? null : window,
  configureStorage()
);

export const extractIdTokenFromWindowLocation = (window: Window | null): string | undefined => {
  if (!window || !window.location || !window.location.href) {
    return undefined;
  }

  if (typeof window.location.href !== 'string') {
    return undefined;
  }

  let token: string | undefined;
  
  window.location.href.replace(/id_token=([^&]+)/u, (_, it: string) => {
    token = it;
    return '';
  });

  return token;
};


export const configureIdToken = (window: Window | null, storage: { discardAccessToken?: () => void; getAccessToken?: () => any; hasAccessToken?: () => boolean; saveAccessToken?: (token: any) => void; discardIdToken?: () => void; getIdToken?: () => any; hasIdToken?: () => boolean; saveIdToken: any; }) => {
  const urlToken = extractIdTokenFromWindowLocation(window);

  if (urlToken) {
    storage.saveIdToken(urlToken);
  }

  return storage;
};

export const idToken = configureIdToken(
  typeof window === 'undefined' ? null : window,
  configureStorage()
);
