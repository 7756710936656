import styled from "styled-components";

export const Container  = styled.div`
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: #FFFFFF;
    width: 100%;
    height: 98px;
    border-radius: 4px;
`;

export const Content = styled.div`
    border-left: 8px solid green;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    justify-content: space-between;
`;


export const Label = styled.span`
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 25.14px;
    text-align: left;
    color: #697A8B;
`;

export const Value = styled.span`
    font-family: Source Sans Pro;
    font-size: 40px;
    font-weight: 600;
    line-height: 50.28px;
    text-align: left;
    color: #30B4C0;
`;