export const palette = {
    primary: '#30B4C0',
    secondary: '#B23672',

    gray900: '#2A3740',
    gray800: '#4C5667',
    gray700: '#697A8B',
    gray600: '#696969',
    gray500: '#A7AFBB',
    gray400: '#D0D8DE',
    gray300: '#E5EBF0',
    gray200: '#F6F8F9',
    gray100: '#FDFDFD',

    highlight800: '#154B56',
    highlight700: '#206E7E',
    highlight600: '#268897',
    highlight500: '#30B4C0',
    highlight400: '#8DDBE3',
    highlight300: '#CEF0F3',
    highlight200: '#EBF9FA',
    highlight100: '#F3FBFC',

    statusInfo: '#4B80A6',
    statusInfoBg: '#F5F8FB',
    statusSuccess: '#5CB85C',
    statusSuccessBg: '#F6FBF5',
    statusWarning: '#FF8E3C',
    statusWarningBg: '#FDF5F0',
    statusError: '#D90000',
    statusErrorBg: '#FDF3F3',
    statusDisabled: '#E5EBF0',

    ratingOne: '#D90000',
    ratingTwo: '#FF8E3C',
    ratingThree: '#F8C575',
    ratingFour: '#5CB85C',
    ratingFive: '#4B924C',

    drivingStatusAvailable: '#239B7D',
    drivingStatusDriving: '#3690AE',
    drivingStatusResting: '#626B72',
    drivingStatusWorking: '#94488B',

    mapMarkerAsset: '#2A3740',
    mapMarkerPoi: '#B23672',
    mapMarkerGeofence: '#5A4876',
    mapMarkerRoute: '#3690AE',

    black: '#000000',
    white: '#FFFFFF',
    transparent: 'transparent',

    bottom_sheat: 'rgba(81, 81, 81, 0.2)',
};

export const theme = {
    colors: {
        ...palette,
    },

    fontFamily: {
        primay: 'SourceSans3-Pro',
        regular: 'SourceSans3-Regular',
        Bold: 'SourceSans3-Bold',
        SemiBold: 'SourceSans3-SemiBold',
    },

    spacing: {
        s0: '0px',
        s2: '2px',
        s4: '4px',
        s6: '6px',
        s8: '8px',
        s10: '10px',
        s12: '12px',
        s14: '14px',
        s16: '16px',
        s20: '20px',
        s24: '24px',
        s28: '28px',
        s30: '30px',
        s32: '32px',
        s36: '36px',
        s40: '40px',
        s48: '48px',
        s56: '56px',
        s96: '96px',
    },

    borderRadius: {
        s0: '0px',
        s2: '2px',
        s4: '4px',
        s8: '8px',
        s12: '12px',
        s16: '16px',
        s32: '32px',
        s40: '40px',
    },
    boxShadow: {
        primary: "0 -3px 12px rgba(0, 0, 0, 0.05)"
    },
    textVariants: {
        calendar: {
            fontFamily: 'SourceSans3-Black',
        },
        defaults: {},
    },
};

export type Theme = typeof theme;
export type ThemeColors = keyof Theme['colors'];
