import {
  Button,
  ExpanderList,
  NotFoundState,
  RadioButton,
  TableSearch,
} from "@rio-cloud/rio-uikit";
import Dialog from "@rio-cloud/rio-uikit/Dialog";
import { useState } from "react";
import Paginator from "src/components/Paginator";

interface IVehicle {
  id: string;
  name: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  vehicles: Array<IVehicle>;
  handleSaveVehicle: (idVehicle: string, name: string) => void;
  handleCancel: () => void;
  onChangeInput: (text: string) => void;
  inputValue: string;
  page: number;
  pages: number;
  handlePage: (id: number) => void;
}

const AddVehicle = ({
  open,
  onClose,
  vehicles,
  handleSaveVehicle,
  handleCancel,
  inputValue,
  onChangeInput,
  handlePage,
  page,
  pages,
}: Props) => {
  const [selected, setSelected] = useState("");
  const [name, setName] = useState("");

  const content = () => {
    return (
      <div>
        {vehicles && vehicles.length > 0 ? (
          vehicles.map((vehicle) => {
            return (
              <RadioButton
                name="radios"
                id={vehicle.id}
                value={vehicle.id}
                checked={selected === vehicle.id}
                onChange={() => {
                  setSelected(vehicle.id);
                  setName(vehicle.name);
                }}
                style={{
                  padding: 11,
                  borderBottom: "1px solid #E5EBF0",
                }}
              >
                <span className="rioglyph rioglyph-truck" />
                <span
                  className="text-normal text-size-14"
                  style={{ marginLeft: 2 }}
                >
                  {vehicle.name}
                </span>
              </RadioButton>
            );
          })
        ) : (
          <NotFoundState
            headline="Nenhum veículo disponível"
            message="Volte e tente novamente."
            fullWidth
            outerClassName="border-none"
          />
        )}
      </div>
    );
  };

  const header = (name: string, length: number) => {
    return (
      <div className="display-flex align-items-center justify-content-between">
        <span>{name}</span>
        <span className="badge rounded padding-5 margin-right-10 bg-lighter">
          {length}
        </span>
      </div>
    );
  };

  return (
    <Dialog
      show={open}
      title={"Vincular veículo"}
      footer={
        <div className="margin-top-20 display-flex gap-10 justify-content-end flex-grow">
          <Button
            bsSize={Button.MD}
            style={{ width: 84 }}
            onClick={() => {
              setSelected("");
              setName("");
              handleCancel();
            }}
          >
            Voltar
          </Button>
          <Button
            disabled={!selected}
            bsStyle={Button.PRIMARY}
            bsSize={Button.MD}
            style={{ width: 84 }}
            onClick={() => handleSaveVehicle(selected, name)}
          >
            Salvar
          </Button>
        </div>
      }
      onClose={onClose}
      body={
        <>
          <div className="display-flex gap-20">
            <div className="border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
              <span
                className={`rioglyph rioglyph-truck text-size-h1 text-color-primary`}
              />
            </div>
            <div className="width-100pct">
              <div className="margin-bottom-20">
                <TableSearch
                  value={inputValue}
                  onChange={(text) => onChangeInput(text)}
                  placeholder="Buscar veículo"
                />
              </div>
              <ExpanderList
                bordered={true}
                unmountOnExit={false}
                rounded={false}
                items={[
                  {
                    id: 1,
                    header: header("Todos os veículos", vehicles?.length),
                    body: content(),
                  },
                  // {
                  //   id: 2,
                  //   header: header("Delivery", 42),
                  //   body: content(),
                  // },
                  // {
                  //   id: 3,
                  //   header: header("Contellation", 42),
                  //   body: content(),
                  // },
                  // {
                  //   id: 4,
                  //   header: header("Meteor", 42),
                  //   body: content(),
                  // },
                ]}
                className="margin-bottom-20"
              />
            </div>
          </div>
          <div style={{ paddingLeft: 70, paddingRight: 70 }}>
            <Paginator handlePage={handlePage} page={page} pages={pages} />
          </div>
        </>
      }
      className=""
      bodyClassName="padding-20"
      showCloseButton
    />
  );
};

export default AddVehicle;
