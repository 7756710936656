import CardCount from "src/components/CardCount";
import PageContainer from "../../components/routes/PageContainer";
import RoutesTable from "src/components/TableRoutes";
import { useEffect, useState } from "react";
import { excludeRoute, fetchRoutesList } from "src/services/routes";
import { Notification } from "@rio-cloud/rio-uikit";

const RoutesScreen = () => {
  const [routes, setRoutes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("desc");
  const [fieldSort, setFieldSort] = useState("name");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [dashboard, setDashboard] = useState({
    lowestEnergyConsumer: 0,
    moreEconomics: 0,
    moreFastest: 0,
    saved: 0,
  });

  const handleCreateRoute = () => {
    const currentParams = new URLSearchParams(window.location.search);
    const url = "/routing/routes/create?" + currentParams.toString();
    window.location.href = url;
  };

  const handleUpdateRoute = (id: string) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set("id", id);
    const url = "/routing/routes/update?" + currentParams.toString();
    window.location.href = url;
  };

  const initialData = async (pageToSearch?: number) => {
    try {
      const res = await fetchRoutesList(
        pageToSearch || page,
        10,
        searchValue,
        sort,
        fieldSort
      );

      if (res.data.result.routes) {
        setDashboard({
          lowestEnergyConsumer: res.data.result.lowestEnergyConsumer,
          moreEconomics: res.data.result.moreEconomics,
          moreFastest: res.data.result.moreFastest,
          saved: res.data.result.saved,
        });
        setRoutes(res.data.result.routes);
        setPages(res.data.result.pages);
      }
    } catch (error) {
      Notification.error("Não foi possível buscar as rotas.");
    } finally {
      if (pageToSearch) {
        setPage(pageToSearch);
      }
    }
  };

  useEffect(() => {
    initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchValue, sort]);

  const handleDownload = () => {};

  const handleDeleteRoute = async (id: string) => {
    try {
      await excludeRoute(id);
      Notification.info("Rota excluída com sucesso!");
      initialData(page > 1 && routes.length === 1 ? page - 1 : page);
    } catch (error) {
      Notification.error("Erro ao excluir rota!");
    }
  };

  return (
    <PageContainer>
      <div style={{ display: "flex", gap: 24 }}>
        <div style={{width: 400}}>
          <CardCount
            borderColor="#30B4C0"
            label="ROTAS SALVAS"
            value={dashboard.saved}
          />
        </div>
        {/* <CardCount
          borderColor="#239B7D"
          label="MENOR CONSUMO ENERGÉTICO"
          value={dashboard.lowestEnergyConsumer}
        />
        <CardCount
          borderColor="#239B7D"
          label="MAIS ECONÔMICAS"
          value={dashboard.moreEconomics}
        />
        <CardCount
          borderColor="#239B7D"
          label="MAIS RÁPIDAS"
          value={dashboard.moreFastest}
        /> */}
      </div>
      <RoutesTable
        showHeader
        pages={pages}
        handleDownload={handleDownload}
        handleSort={(dir, field) => {
          setSort(dir);
          setFieldSort(field);
        }}
        routes={routes}
        viewType="TABLE"
        page={page}
        handleCreateRoute={handleCreateRoute}
        handleUpdateRoute={handleUpdateRoute}
        handleSearchValueChange={(text) => setSearchValue(text)}
        handleDeleteRoute={handleDeleteRoute}
        searchValue={searchValue}
        handlePage={(page) => {
          setPage(page);
        }}
      />
    </PageContainer>
  );
};

export default RoutesScreen;
