export const navigateTo = (urlString: string) => {
  const currentUrl = new URL(window.location.href);
  const newUrl = new URL(urlString, currentUrl.origin);

  // Preserva os parâmetros da URL atual
  currentUrl.searchParams.forEach((value, key) => {
    newUrl.searchParams.set(key, value); // Substitui o valor existente
  });

  // Navega para a nova URL
  window.location.href = newUrl.toString();
};
