import { TableOnGoing } from '@components';
import * as St from './styles';
import { useOnGoingTrips } from './useOnGoingTrips';

const OnGoingTripsScreen = () => {
    const {
        value
    } = useOnGoingTrips();

    return (
        <St.Container>
            <span>Viagens em andamento {value}</span>
            <TableOnGoing viewType='TABLE' />
        </St.Container>
    )
}

export default OnGoingTripsScreen;