import { useDispatch } from "react-redux"
import { setValue, useAppSelector } from "../../store";
import { useEffect } from "react";

export const useOnGoingTrips = () => {
    const dispatch  = useDispatch();
    const {value} = useAppSelector(state => state.example);

    useEffect(() => {
        dispatch(setValue("example"));
    }, []);

    return {
        value,
    }
}