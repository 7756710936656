import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Usa localStorage no navegador
import { reducers } from './reducers';

// Configuração de persistência
const persistConfig = {
  key: 'root', // Chave para salvar no localStorage
  storage,     // Usa o localStorage do navegador
  whitelist: ['trips'] // Apenas o estado 'trips' será persistido
};

// Aplica o persistReducer para persistir os dados da store
const persistedReducer = persistReducer(persistConfig, reducers);

// Configura a store com o reducer persistido
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignora as ações geradas pelo Redux Persist
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

// Cria o persistor para gerenciar o estado persistente
export const persistor = persistStore(store);

// Tipos para TypeScript
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch;
