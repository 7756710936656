import { useState } from "react";
import omit from "lodash/fp/omit";
import isEmpty from "lodash/fp/isEmpty";
import classNames from "classnames";
import TableViewToggles, {
  type TableViewTogglesViewType,
} from "@rio-cloud/rio-uikit/TableViewToggles";
import SortDirection, {
  type SortDirectionType,
} from "@rio-cloud/rio-uikit/SortDirection";
import SortArrows from "@rio-cloud/rio-uikit/SortArrows";
import ButtonDropdown from "@rio-cloud/rio-uikit/ButtonDropdown";
import NotFoundState from "@rio-cloud/rio-uikit/NotFoundState";
import { Button } from "@rio-cloud/rio-uikit";

const defaultColumnOrder = [
  "name",
  "origin",
  "destination",
  "stops",
  "distance",
  "duration",
  "register",
  "vwcoin",
  "actions",
];

type ColumnLabel = {
  [key: string]: string;
};
const columnLabels: ColumnLabel = {
  name: "Nome",
  origin: "Origem",
  destination: "Destino",
  stops: "Paradas",
  distance: "Distância",
  duration: "Duration",
  register: "Cadastro",
  vwcoin: "VWCoin",
  actions: "",
};

type ColumnDetails = {
  [key: string]: number;
};

type ColumnDetailsMap = {
  [key: string]: ColumnDetails;
};

const demoColumnsDetails: ColumnDetailsMap = {
  name: {
    width: 200,
    defaultWidth: 200,
    maxWidth: 350,
  },
  origin: {
    width: 250,
    defaultWidth: 250,
    maxWidth: 350,
  },
  destination: {
    width: 250,
    defaultWidth: 250,
    maxWidth: 350,
  },
  stops: {
    width: 170,
    defaultWidth: 170,
    maxWidth: 200,
  },
  performance: {
    width: 278,
    defaultWidth: 278,
    maxWidth: 300,
  },
};

type Route = {
  id: string;
  name: string;
  origin: string;
  destination: string;
  stops: string;
  distance: string;
  duration: string;
  register: string;
  vwcoin: string;
};

const getSortDir = (
  sortDir: SortDirectionType,
  sortBy: string,
  previousSortBy: string
) => {
  if (sortBy === previousSortBy) {
    return sortDir === SortDirection.ASCENDING
      ? SortDirection.DESCENDING
      : SortDirection.ASCENDING;
  }
  return SortDirection.ASCENDING;
};

export type Props = {
  viewType: TableViewTogglesViewType;
  pages: number;
  routes: Route[];
  searchValue: string;
  page: number;
  handleSearchValueChange: (text: string) => void;
  handleUpdateRoute: (id: string) => void;
  handleDeleteRoute: (id: string) => void;
  handlePage: (page: number) => void;
  handleSort: (dir: string, newSort: string) => void;
  handleSelect: (id: string) => void;
  showHeader?: boolean;
};

const RoutesTableTrip = (props: Props) => {
  const { viewType: externalViewType } = props;
  const [sortBy, setSortBy] = useState("name");
  const [sortDir, setSortDir] = useState<SortDirectionType>(
    SortDirection.ASCENDING
  );
  const [columnOrder, setColumnOrder] = useState<string[]>(defaultColumnOrder);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [columnsDetails, setColumnsDetails] = useState(demoColumnsDetails);
  const [viewType, setViewType] = useState(
    externalViewType || TableViewToggles.VIEW_TYPE_TABLE
  );

  const handleSortChange = (event: React.MouseEvent<HTMLElement>) => {
    const newSortBy = event.currentTarget.getAttribute("data-sortby");
    if (newSortBy) {
      handleCardSortChange(newSortBy, getSortDir(sortDir, newSortBy, sortBy));
      props.handleSort(sortDir, newSortBy);
    }
  };

  const handleCardSortChange = (
    newSortBy: string,
    newSortDir: SortDirectionType
  ) => {
    setSortBy(newSortBy);
    setSortDir(newSortDir);
  };

  // May be extracted as a dedicated component but for demo purpose it's shown here
  const renderTableHead = (
    column: string,
    label: string,
    sortByColumn: string,
    sortDirection: SortDirectionType
  ) => {
    const tableHeadClassNames = classNames("user-select-none", "sort-column");

    return (
      <th
        key={column}
        className={tableHeadClassNames}
        onClick={handleSortChange}
        data-field={column}
        data-sortby={column}
        title={label}
      >
        {column === "actions" ? null : (
          <span>
            {sortByColumn === column ? (
              <SortArrows direction={sortDirection} />
            ) : (
              <SortArrows />
            )}
            <span>{label}</span>
          </span>
        )}
      </th>
    );
  };

  const renderTableCaption = (column: string, columnDetails: ColumnDetails) => {
    const style = columnDetails?.width
      ? {
          minWidth: columnDetails.width,
          width: columnDetails.width,
        }
      : {};

    return <col key={column} style={style} />;
  };

  // filter for hidden columns
  const columns = columnOrder.filter((name) => !hiddenColumns.includes(name));

  // filter data to omit hidden columns
  const withoutHidden = omit(hiddenColumns);
  const filteredRows = props.routes.map((vehicle) => ({
    ...withoutHidden(vehicle),
  })) as Route[];

  // in case a search value is given, filter the data accordingly
  const searchResult = filteredRows;

  // Sort rows according to the sortBy and sortDir settings
  const rows = searchResult;

  const tableClassNames = classNames(
    "table",
    "table-layout-fixed",
    "table-column-overflow-hidden",
    "table-bordered",
    "table-sticky",
    "table-head-filled",
    viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD &&
      "table-cards table-single-card",
    viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS &&
      "table-cards table-multi-cards"
  );

  const isViewTypeTable = viewType === TableViewToggles.VIEW_TYPE_TABLE;

  const icons = {
    id: "",
    name: "rioglyph rioglyph-route-option margin-right-10 text-color-gray text-size-20",
    origin:
      "rioglyph rioglyph-start margin-right-10 text-color-gray text-size-16",
    destination:
      "rioglyph rioglyph-finish margin-right-10 text-color-gray text-size-16",
    stops:
      "rioglyph rioglyph-pushpin margin-right-10 text-color-gray text-size-16",
    distance:
      "rioglyph rioglyph-road margin-right-10 text-color-gray text-size-16",
    duration:
      "rioglyph rioglyph-time margin-right-10 text-color-gray text-size-16",
    register: "",
    vwcoin:
      "rioglyph rioglyph-circle-stack margin-right-10 text-color-gray text-size-16",
  };

  return (
    <div id="TableCommonDemo">
      <div>
        {isEmpty(rows) && !isViewTypeTable && (
          <NotFoundState
            headline="Sem resultados"
            message="Por favor refaça a busca."
          />
        )}
        <table className={tableClassNames}>
          <colgroup>
            {columns.map((column) =>
              renderTableCaption(column, columnsDetails[column])
            )}
            <col className="table-action" />
          </colgroup>
          <thead>
            <tr>
              {columns.map((column) =>
                renderTableHead(column, columnLabels[column], sortBy, sortDir)
              )}
              <th className="table-action" />
            </tr>
          </thead>
          <tbody>
            {isEmpty(rows) && isViewTypeTable && (
              <tr>
                <td colSpan={columns.length + 1}>
                  <NotFoundState
                    outerClassName="border-none"
                    headline="Sem resultados"
                    message="Por favor refaça a busca."
                  />
                </td>
              </tr>
            )}
            {rows.map((row: Route, index: number) => {
              return (
                <tr key={index}>
                  {columns.map((col) => {
                    return col === "actions" ? (
                      <td key={col} data-field={columnLabels[col]}>
                        <Button
                          onClick={() => props.handleSelect(row.id)}
                          bsStyle={Button.PRIMARY}
                          bsSize={Button.MD}
                          style={{ width: 112, height: 34 }}
                        >
                          Selecionar
                        </Button>
                      </td>
                    ) : (
                      <td key={col} data-field={columnLabels[col]}>
                        {row[col as keyof Route] ? (
                          <span className={icons[col as keyof Route]}></span>
                        ) : null}
                        <span>{row[col as keyof Route]}</span>
                      </td>
                    );
                  })}
                  <td className="table-action">
                    <span>
                      <ButtonDropdown
                        bsSize="md"
                        dropdownClassName="width-200"
                        title={
                          <span className="rioglyph rioglyph-option-vertical" />
                        }
                        variant="link"
                        iconOnly
                        items={[
                          {
                            value: (
                              <div
                                onClick={() => props.handleUpdateRoute(row.id)}
                              >
                                <span className="rioglyph rioglyph-map-location margin-right-10 text-color-primary" />
                                <span>Editar Rota</span>
                              </div>
                            ),
                          },
                          // {
                          //   value: (
                          //     <div>
                          //       <span className="rioglyph rioglyph-map-location margin-right-10 text-color-primary" />
                          //       <span>Abrir rota no mapa</span>
                          //     </div>
                          //   ),
                          // },
                          // {
                          //   value: (
                          //     <div>
                          //       <span className="rioglyph rioglyph-calendar margin-right-10 text-color-primary" />
                          //       <span>Agendar viagem</span>
                          //     </div>
                          //   ),
                          // },
                          // {
                          //   value: (
                          //     <div>
                          //       <span className="rioglyph rioglyph-cost-efficency margin-right-10 text-color-primary" />
                          //       <span>Rota inteligente</span>
                          //     </div>
                          //   ),
                          // },
                          {
                            value: (
                              <div
                                onClick={() => props.handleDeleteRoute(row.id)}
                              >
                                <span className="rioglyph rioglyph-trash margin-right-10 text-color-primary" />
                                <span>Excluir</span>
                              </div>
                            ),
                          },
                        ]}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
            {/* Placeholder workaround for equal with cards of the last row */}
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
            <tr className="table-card-placeholder" />
          </tbody>
        </table>
      </div>

      <div
        className="display-flex gap-50 flex-wrap justify-content-center"
        style={{ marginTop: 40 }}
      >
        <ul className="pagination">
          <li className="disabled">
            <span className="rioglyph rioglyph-chevron-left"></span>
          </li>

          {Array.from({ length: props.pages }, (_, i) => (
            <li
              key={i}
              onClick={() => props.handlePage(i + 1)}
              style={{ background: i + 1 === props.page ? "#A7AFBB" : "" }}
            >
              <span style={{ color: i + 1 === props.page ? "white" : "" }}>
                {i + 1}
              </span>
            </li>
          ))}

          <li>
            <span className="rioglyph rioglyph-chevron-right"></span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default RoutesTableTrip;
