export type IValueToken = string | null;

export const configureStorage = () => {
  let accessToken: IValueToken = null;
  let idToken: IValueToken = null;

  return {
    discardAccessToken: () => {
      accessToken = null;
    },
    getAccessToken: () => accessToken,
    hasAccessToken: () => Boolean(accessToken),
    saveAccessToken: (token: string) => {
      accessToken = token;
    },
    discardIdToken: () => {
      idToken = null;
    },
    getIdToken: () => idToken,
    hasIdToken: () => Boolean(idToken),
    saveIdToken: (token: string) => {
      idToken = token;
    }
  };
};
