import { Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "@store";
import screens from "@screens";
import { Page } from "@components";
import { ThemeProvider } from "styled-components";
import { theme } from "@theme";
import "./styles/styles.css";
import { NotificationsContainer } from "@rio-cloud/rio-uikit";
import { PersistGate } from "redux-persist/integration/react";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Page>
            <NotificationsContainer />
            <Routes>
              <Route
                path="/"
                element={<Navigate to="/routing/trips" replace />}
              />
              <Route
                path="/routing/map/trips"
                Component={screens.OnGoingTripsScreen}
              />
              <Route path="/routing/trips" Component={screens.TripsScreen} />
              <Route path="/routing/zone" Component={screens.AreasScreen} />
              <Route path="/routing/routes" Component={screens.RoutesScreen} />
              <Route
                path="/routing/routes/create"
                Component={screens.RoutesCreateScreen}
              />
              <Route
                path="/routing/routes/update"
                Component={screens.RoutesUpdateScreen}
              />
              <Route
                path="/routing/perfomance"
                Component={screens.PerformanceScreen}
              />
              <Route
                path="/routing/trips/routes"
                Component={screens.TripsListRouteScreen}
              />
              <Route
                path="/routing/trips/driver"
                Component={screens.TripsDriverScreen}
              />
              <Route
                path="/routing/trips/schedule"
                Component={screens.TripsSchedule}
              />
            </Routes>
          </Page>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
