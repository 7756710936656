import Dialog from "@rio-cloud/rio-uikit/Dialog";

interface Props {
  open: boolean;
  onClose: () => void;
  onClickSelectRoute: () => void;
}

interface Card {
  icon: string;
  text: string;
  textBtn: string;
  onClick: () => void;
}

const AddTrip = ({ open, onClose, onClickSelectRoute }: Props) => {
  const RenderCard = ({ icon, onClick, text, textBtn }: Card) => {
    return (
      <div
        className="display-flex border-width-1 border-style-solid rounded width-100pct justify-content-center align-items-center shadow-default"
        style={{
          flexDirection: "column",
          gap: 24,
          padding: 40,
          borderColor: "#D0D8DE",
        }}
      >
        <div className="border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
          <span className={`${icon} text-size-h1 text-color-primary`} />
        </div>
        <span className="text-size-16 line-height-20 text-normal">{text}</span>
        <button
          type="button"
          className="btn btn-primary btn-lg"
          onClick={onClick}
        >
          <span className="text-capitalize">{textBtn}</span>
        </button>
      </div>
    );
  };

  return (
    <Dialog
      show={open}
      title={"Agendar viagem"}
      onClose={onClose}
      body={
        <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
          <RenderCard
            icon="rioglyph rioglyph-route-option"
            text="Agendar viagem usando uma rota salva"
            textBtn="Continuar"
            onClick={onClickSelectRoute}
          />
          {/* <RenderCard
            icon="rioglyph rioglyph-route-option"
            text="Agendar viagem usando uma rota salva"
            textBtn="Continuar"
            onClick={() => console.warn("")}
          /> */}
        </div>
      }
      className="exampleDialog"
      showCloseButton
      bsSize="md"
    />
  );
};

export default AddTrip;
