import { DatePicker } from "@rio-cloud/rio-uikit";

interface Props {
  label?: string;
  value: string | Date | moment.Moment | undefined;
  timeFormat?: string | boolean;
  dateFormat: string | boolean;
  onChange?:
    | ((value: moment.Moment | string, isValid: boolean) => void)
    | undefined;
    inputProps?: React.HTMLProps<HTMLInputElement> | undefined
}

const InputDate = ({
  label,
  value,
  timeFormat,
  dateFormat,
  onChange,
  inputProps
}: Props) => {
  return (
    <div>
      {label ? (
        <div className="margin-bottom-4">
          <span className="text-size-14 text-normal text-color-dark line-height-16">
            {label}
          </span>
        </div>
      ) : null}
    
      <DatePicker
        className="min-width-200 padding-left-0"
        inputProps={inputProps}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        locale="pt-BR"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default InputDate;
