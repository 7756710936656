import * as St from './styles'

interface Props {
    borderColor: string;
    label: string;
    value: string | number;

}

const CardCount = ({borderColor, label, value}: Props) => {
    return (
        <St.Container className='rounded bg-white shadow-smooth'>
            <St.Content style={{borderLeftColor: borderColor}}>
                <St.Label>{label}</St.Label>
                <St.Value>{value}</St.Value>
            </St.Content>
        </St.Container>
    )
}

export default CardCount;