import { Chip, InputDate } from "@components";
import { Button, DatePicker, Notification } from "@rio-cloud/rio-uikit";
import { clearTrip, setIdRouteSelected, useAppSelector } from "@store";
import { formatDateBr, formatDateEn, formatTimeBr, navigateTo } from "@utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageContainer from "src/components/routes/PageContainer";
import { sendTrip } from "src/services/trips";

const TripsSchedule = () => {
  const [hourValue, setHourValue] = useState("");
  const dispatch = useDispatch();

  const [value, setValue] = useState<Date | null | string>();

  const { idRouteSelected, idDriverSelected, idVehicleSelected, nameDriverSelected, nameVehicleSelected } =
    useAppSelector((state) => state.trips);

  const handleDateChange = (selectedDate: string) => {
    const date = formatDateBr(selectedDate, false);
    setValue(date);
  };

  const handleSubmit = async () => {
    try {
      let trips = [];
      trips.push({
        driverId: idDriverSelected?.replace("prod-rio-users:", "") as string,
        assetId: idVehicleSelected as string,
        routeId: idRouteSelected as string,
        assetName: nameVehicleSelected as string,
        driverName: nameDriverSelected as string,
        startDateTime: `${formatDateEn(
          value?.toString()
        )}T${hourValue}:00+00:00`,
      });

      const body = {
        linkedVehicles: trips,
      };

      await sendTrip(body, idRouteSelected as string);

      Notification.success("Viagem enviada com sucesso.");

      setTimeout(() => {
        dispatch(clearTrip());
        navigateTo("/routing/trips");
      }, 1000);
    } catch (error) {
      Notification.error("Não foi possível enviar a viagem.");
    }
  };

  const handleCancel = () => {
    navigateTo('routing/trips/driver');
  }

  return (
    <PageContainer>
      <div>
        <div className="display-flex align-items-center gap-20">
          <div className="border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
            <span
              className={`rioglyph rioglyph-calendar text-size-h1 text-color-primary`}
            />
          </div>
          <p className="text-size-h2 text-bold">Agendamento da viagem</p>
        </div>
        <div
          className="bg-white rounded shadow-default margin-top-25"
          style={{ padding: 75 }}
        >
          <div className="display-flex align-items-center justify-content-between">
            <div className="display-flex align-items-center gap-10">
              <div
                className="width-30 height-30 rounded-circle aspect-ratio-1 bg-size-100pct bg-repeat-no bg-light"
                style={{
                  backgroundImage: `url(${"https://cdn.rio.cloud/svg/common/ico_rio_colored.svg"})`,
                }}
              />

              <div>
                <span className="text-size-16 text-normal line-height-20 text-capitalize">
                  {nameDriverSelected}
                </span>
              </div>
            </div>
            {/* <div>
              <Button
                bsStyle={Button.DEFAULT}
                iconName="rioglyph rioglyph-calendar"
                onClick={() => console.log("")}
                className="margin-left-10"
              >
                Ver calendário
              </Button>
            </div> */}
          </div>
          <div
            className="margin-top-50 display-flex gap-15"
            style={{ flexDirection: "column" }}
          >
            <span className="text-bold text-size-16">
              Informe a data e hora da viagem:
            </span>
            <div className="margin-top-10">
              <div>
                <InputDate
                  label="Data"
                  inputProps={{ placeholder: "Selecione o dia.", id: "1" }}
                  dateFormat="DD/MM/YYYY"
                  timeFormat={false}
                  value={value as Date}
                  onChange={(t) => handleDateChange(t.toString())}
                />
              </div>
              <div>
                <InputDate
                  label="Hora"
                  inputProps={{ placeholder: "Selecione o horário.", id: "2" }}
                  dateFormat={false}
                  timeFormat="HH:mm"
                  value={hourValue}
                  onChange={(result) =>
                    setHourValue(
                      formatTimeBr(
                        new Date(result.toString()),
                        "HOUR_AND_MINUTES"
                      )
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="margin-top-20 display-flex gap-10 justify-content-end flex-grow">
            <Button bsSize={Button.MD} style={{ width: 84 }} onClick={handleCancel}>
              Voltar
            </Button>
            <Button
              disabled={!value || !hourValue}
              bsStyle={Button.PRIMARY}
              bsSize={Button.MD}
              onClick={handleSubmit}
            >
              Concluir agendamento da viagem
            </Button>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default TripsSchedule;
