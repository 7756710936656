import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IExampleState } from '@types';

const initialState: IExampleState = {
  value: '',
};

export const ExampleSlice = createSlice({
  name: 'example',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { setValue } = ExampleSlice.actions;

export default ExampleSlice.reducer;
