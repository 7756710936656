import AreasScreen from "./areas";
import OnGoingTripsScreen from "./onGoingTrips";
import PerformanceScreen from "./performance";
import RoutesScreen from "./routes";
import RoutesCreateScreen from "./RoutesCreate";
import RoutesUpdateScreen from "./RoutesUpdate";
import TripsScreen from "./trips";
import TripsDriverScreen from "./tripsDriver";
import TripsListRouteScreen from "./tripsListRoute";
import TripsSchedule from "./tripsSchedule";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  AreasScreen,
  OnGoingTripsScreen,
  PerformanceScreen,
  RoutesScreen,
  TripsScreen,
  RoutesCreateScreen,
  RoutesUpdateScreen,
  TripsListRouteScreen,
  TripsDriverScreen,
  TripsSchedule
};
