export const formatDateBr = (dateString: string, showHour = true) => {
  if (!dateString) return "";
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses são baseados em zero
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return showHour
    ? `${day}/${month}/${year} ${hours}:${minutes}`
    : `${day}/${month}/${year}`;
};

export const formatTimeBr = (
  date: Date,
  type?: "DEFAULT" | "HOUR" | "MINUTES" | "SECONDS" | "HOUR_AND_MINUTES"
) => {
  if (!date) return "";

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  switch (type) {
    case "HOUR":
      return hours;
    case "MINUTES":
      return minutes;
    case "SECONDS":
      return seconds;
    case "HOUR_AND_MINUTES":
      return `${hours}:${minutes}`;
    default:
      return `${hours}:${minutes}:${seconds}`;
  }
};

export const formatDateEn = (dateString?: string) => {
  if (!dateString) return "";

  const [day, month, year] = dateString.split('/');

  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}