import { ExpanderListDriver, Paginator } from "@components";
import {
  Button,
  NotFoundState,
  Notification,
  TableSearch,
} from "@rio-cloud/rio-uikit";
import { setIdDriverSelected, setNameDriverSelected } from "@store";
import { Driver } from "@types";
import { navigateTo } from "@utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageContainer from "src/components/routes/PageContainer";
import { fetchDriverList } from "src/services/routes";

const TripsDriverScreen = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState("");
  const [page, setPage] = useState(1);
  const [data, setData] = useState<Driver[]>([]);
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [nameS, setName] = useState("");
  const [userId, setUserId] = useState("");
  const [pages, setPages] = useState(1);

  const initialData = async () => {
    try {
      const res = await fetchDriverList(page, 10, searchValue, "ASC");
      if (res.data.result) {
        setData(res.data.result.items);
        setPages(res.data.result.pages);
      }
    } catch (error) {
      Notification.error("Não foi possível buscar as rotas.");
    }
  };

  useEffect(() => {
    initialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchValue]);

  // Não remover.
  // const handleChecked = (checked: boolean, id: string) => {
  //   setSelectedItems((prev) => {
  //     if (checked) {
  //       return [...prev, id];
  //     } else {
  //       return prev.filter((item) => item !== id);
  //     }
  //   });
  // };

  const handleChecked = (
    checked: boolean,
    id: string,
    name: string,
    userIdN?: string
  ) => {
    setSelectedItems(id);
    setName(name);
    setUserId(userIdN as string);
  };

  const handleSubmit = () => {
    dispatch(setIdDriverSelected(userId));
    dispatch(setNameDriverSelected(nameS));
    navigateTo("/routing/trips/schedule");
  };

  const handleCancel = () => {
    navigateTo("/routing/trips/routes");
  };

  return (
    <PageContainer>
      <div className="display-flex align-items-center gap-20">
        <div className="border border-color-primary border-width-3 border-style-solid width-50 height-50 display-flex justify-content-center align-items-center">
          <span
            className={`rioglyph rioglyph-user text-size-h1 text-color-primary`}
          />
        </div>
        <p className="text-size-h2 text-bold">Motorista para a viagem</p>
      </div>
      <div>
        <TableSearch
          value={searchValue}
          onChange={(text) => setSearchValue(text)}
          placeholder="Buscar motorista"
        />
      </div>
      <div className="padding-50 bg-white rounded shadow-default">
        <div className="margin-bottom-25">
          <span className="text-bold text-size-16">
            {data?.length} motoristas encontrados:
          </span>
        </div>
        {data && data.length > 0 ? (
          <ExpanderListDriver
            onChecked={handleChecked}
            selected={selectedItems} //temp remover
            onClickButton={(type, id) => console.log(type, id)}
            data={data}
          />
        ) : (
          <NotFoundState
            headline="Nenhum motorista disponível"
            message="Volte e tente novamente."
            fullWidth
            outerClassName="border-none"
          />
        )}

        <Paginator
          handlePage={(page) => setPage(page)}
          pages={pages}
          page={page}
        />

        <div className="margin-top-20 display-flex gap-10 justify-content-end flex-grow">
          <Button
            bsSize={Button.MD}
            style={{ width: 84 }}
            onClick={handleCancel}
          >
            Voltar
          </Button>
          <Button
            disabled={!selectedItems}
            bsStyle={Button.PRIMARY}
            bsSize={Button.MD}
            style={{ width: 157 }}
            onClick={handleSubmit}
          >
            Buscar motorista
          </Button>
        </div>
      </div>
    </PageContainer>
  );
};

export default TripsDriverScreen;
